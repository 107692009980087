export const tableCols = [
  { label: '订单编号', prop: 'orderCode', width: '150' },
  { label: '订单状态', prop: 'orderStatus', width: '150', slotName: 'orderStatusSlot' },
  { label: '物流渠道', prop: 'expressCompanyName', width: '100' },
  { label: '运单号', prop: 'expressWaybillCode', width: '150' },
  { label: '业务员', prop: 'userName', width: '160',slotName: 'userName' },
  { label: '物流到期时间', prop: 'expressExpireTime', width: '120', slotName: 'beOverdueSlot' },
  { label: '刀版图状态', prop: 'isKnifeLayoutCreated', width: '100', slotName: 'isKnifeState' },
  { label: '时间', prop: 'time', width: '160', slotName: 'timeSlot' },
  { label: '操作', width: '250', slotName: 'operationSlot' }
]
